import React from 'react'
import { Card, CardTitle, CardBody, CardText, CardImg } from 'reactstrap';
export const Help = () => {
  return (
    <header className="content center text-dark ml-auto fade-in">
        <h1 className='mb-2'>How You Can Help</h1>
        <Card className="Card shadow">
        <div className='card-img-container pt-2'>
            <CardImg
            className='kitty-header'
            alt="Card image cap"
            src={require('../assets/kittens2.png')}
            top
            />
        </div>

        <CardBody>
        <CardText tag='h6' className='kitty-card-text'>
            <p className="mb-1">There are many ways that you can help to support Nine Lives of Norton:</p>
            <Card className='p-3 mb-3'>
            <p className="mb-1"><a href='/#/volunteers'>Become a Nine Lives of Norton volunteer</a></p>
            <p className="mb-1"><a href='/#/adoptacage'>Adopt-A-Cage Program</a></p>
            <p className="mb-1"><a href='/#/foster'>Become a foster parent</a></p>
            <p className="mb-1"><a href='http://www.ninelivesofnorton.org/ShoesWeRecycleforReuse-1.pdf'>We also collect your gently used shoes!</a></p>
            <p className="mb-1"><a href='http://www.carshelpingamerica.org/supported-charities-nine-lives.html'>Donate a car</a></p>
            <p className="mb-1"> <a href='https://www.chewy.com/g/nine-lives-of-norton-feline-rescue-shelter-inc_b118426825#wish-list&wishlistsortby=DEFAULT'>Donate Items on our Wish List</a></p>
            <p className='mb-0'><a href='https://www.networkforgood.org/donation/ExpressDonation.aspx?ORGID2=043433595'>Donate safely and securely online using Network for Good.</a></p>
            </Card>
            <h4 className="mb-1">Donate directly to Nine Lives:</h4>
            <Card className='p-3'>
                <p className='mb-1'>Monetary Donations can be sent to:</p>
                <Card className='address-text mb-3 p-3'>
                    <p className='mb-0'>Nine Lives of Norton Feline Rescue Shelter INC.</p>
                    <p className='mb-0'>P.O. Box 548</p>
                    <p className="mb-0">Norton, MA 02766</p>
                </Card>
            
            <div>

                <p>Below is a list of items that we use on a regular basis:</p> 
                <ul>
                    <Card className='p-3'>
                    <li>Friskies Canned Cat Food</li>     
                    <li>Dry Cat Food (Purina ONE or IAMS)</li>
                    <li>Dry Kitten Food (Purina Kitten Chow)</li>
                    <li>Paper Towels</li>
                    <li>Laundry Soap </li>
                    <li>Bleach</li>
                    <li>Dish Soap </li>
                </Card>
                
                </ul>
            </div>
            <p className="mb-1">Nine Lives of Norton collects returnable cans and bottles as a fundraiser.</p>
            </Card>
        </CardText>
        </CardBody>
    </Card>

    </header>
  )
}
