import React from 'react'

export const Footer = () => {
    return (
    <footer class="footer">
      <div class="text-center bg-dark">
        <p class="text-muted mb-0">Nine Lives of Norton</p>
        <span class="text-muted">84 Hill St, Norton, MA 02766</span>
      </div>
    </footer>
    )
}
