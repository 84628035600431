import { Card, CardTitle, CardBody, CardText, CardImg } from 'reactstrap';
import { KittyCard } from './KittyCard';
export const Contact = () => {
  return (
    <KittyCard title='Contact Us' pic={require('../assets/captain.png')}>
        <p className='mb-0'>We'd love to hear from you! </p>
        <p>Send us an email and a volunteer will get back to you ASAP.</p>
        <p>We are open most Saturdays from 11am - 1pm (By appointment only)</p>
        <p><a href='mailto:ninelivesofnorton@yahoo.com'>ninelivesofnorton@yahoo.com</a></p>
        <p><a href='tel:508-285-5159'>(508) 285-5159</a></p>
    </KittyCard>
  );
}