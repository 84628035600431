import React from 'react'
import { KittyCard } from './KittyCard';
export const Surrenders = () => {
    return (
        <KittyCard pic={require('../assets/windowkey.png')} title='Surrenders'>
            <p className="mb-1">Nine Lives of Norton serves Norton and surrounding communities.</p>
            <p className="mb-1">Surrenders of adult kitties and kittens are accepted by appointment and on a space-available basis. </p>
            <p className="mb-1">Please contact the shelter for more information: (508) 285-5159</p>
            <p className="mb-1">We are open most saturdays from 11am - 1pm (By appointment only).</p>
            <p className="mb-1">Should you phone at any other time you will most likely meet with the answering machine.</p>
            <p className="mb-1">Volunteers check all the messages left and return calls as soon as they are able.</p>
            <p className="mb-1">Please leave a brief message and a phone number where you can be reached. Speak slowly and clearly when leaving your contact number. Thank you.</p>
        </KittyCard>
        )
}
